import { adminRoutes } from "endpoints/base.endpoint";
import moment from "moment";
import React, { useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactToPrint from "react-to-print";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            defaultValue={"100"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

export default function Devicelogs() {
  const device_id = sessionStorage.getItem("device_id");
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    {
      data: getDeviceLogsByID,
      loading: getDeviceLogsByIDLoading,
      error: getDeviceLogsByIDError,
    },
    refresh,
  ] = adminRoutes(
    {
      url: adminEndpoints.device_logs.path,
      method: adminEndpoints.device_logs.method,
      data: { device_id },
      headers: config.header,
    },
    { manual: false }
  );
  console.log("getDeviceLogsByID", getDeviceLogsByID);
  return (
    <div className="p-4">
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={`h2 ${activeTab === "1" ? "active" : ""}`}
            onClick={() => toggleTab("1")}
          >
            Connection Logs
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`h2 ${activeTab === "2" ? "active" : ""}`}
            onClick={() => toggleTab("2")}
          >
            Management Logs
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="pt-5">
            <Col sm="12">
              <div className="py-4">
                <Container className="mt--6" fluid>
                  <h4 className="h2 my-4">Connection Logs</h4>
                  <Row>
                    <div className="col">
                      <Card>
                        {getDeviceLogsByID &&
                          getDeviceLogsByID?.connectLogs?.length > 0 && (
                            <ToolkitProvider
                              data={getDeviceLogsByID.connectLogs}
                              keyField="_id"
                              columns={[
                                {
                                  dataField: "deviceID",
                                  text: "Device ID",
                                  sort: true,
                                },
                                {
                                  dataField: "hardwareVersion",
                                  text: "Hardware Version",
                                  sort: true,
                                },
                                {
                                  dataField: "firmwareVersion",
                                  text: "Firmware Version",
                                  sort: true,
                                },
                                {
                                  dataField: "ip",
                                  text: "Ip",
                                  sort: true,
                                },
                                {
                                  dataField: "wasGivenOTA",
                                  text: "Was Given OTA",
                                  sort: true,
                                },
                                {
                                  dataField: "wasGivenUpdateConfig",
                                  text: "Was Given Update Config",
                                  sort: true,
                                },
                                {
                                  dataField: "createdAt",
                                  text: "Created at",
                                  sort: true,
                                  formatter: (cell) =>
                                    moment(cell).format(
                                      "MM/DD/YYYY hh:mm:ss A"
                                    ),
                                },
                              ]}
                              search
                            >
                              {(props) => (
                                <div className="py-4 px-4 table-responsive">
                                  <Container fluid>
                                    <Row>
                                      <Col xs={12} sm={9}>
                                        <ButtonGroup className="float-left">
                                          <Button
                                            className="buttons-copy buttons-html5"
                                            color="primary"
                                            size="sm"
                                            id="copy-tooltip"
                                            onClick={() =>
                                              copyToClipboardAsTable(
                                                document.getElementById(
                                                  "react-bs-table"
                                                )
                                              )
                                            }
                                          >
                                            <span>Copy</span>
                                          </Button>
                                          <ReactToPrint
                                            trigger={() => (
                                              <Button
                                                color="primary"
                                                size="sm"
                                                className="buttons-copy buttons-html5"
                                                id="print-tooltip"
                                              >
                                                Print
                                              </Button>
                                            )}
                                            content={() => componentRef.current}
                                          />
                                        </ButtonGroup>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="print-tooltip"
                                        >
                                          This will open a print page with the
                                          visible rows of the table.
                                        </UncontrolledTooltip>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="copy-tooltip"
                                        >
                                          This will copy to your clipboard the
                                          visible rows of the table.
                                        </UncontrolledTooltip>

                                        <div
                                          id="datatable-basic_filter"
                                          className="dataTables_filter px-4 pb-1"
                                        >
                                          <label>
                                            Search:
                                            <SearchBar
                                              className="form-control-sm"
                                              placeholder=""
                                              {...props.searchProps}
                                            />
                                          </label>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <BootstrapTable
                                    ref={componentRef}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    pagination={pagination}
                                    bordered={true}
                                    striped
                                    hover
                                    condensed
                                    id="react-bs-table"
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          )}
                      </Card>
                    </div>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row className="pt-5">
            <Col sm="12">
              <div className="py-4">
                <Container className="mt--6" fluid>
                  <h4 className="h2 my-4">Management Logs</h4>
                  <Row>
                    <div className="col">
                      <Card>
                        {getDeviceLogsByID &&
                          getDeviceLogsByID?.managementLogs?.length > 0 && (
                            <ToolkitProvider
                              data={getDeviceLogsByID.managementLogs}
                              keyField="_id"
                              columns={[
                                {
                                  dataField: "deviceID",
                                  text: "Device ID",
                                  sort: true,
                                },
                                {
                                  dataField: "action_taken",
                                  text: "Action taken",
                                  sort: true,
                                },
                                {
                                  dataField: "action_taken_time",
                                  text: "Actio taken time",
                                  sort: true,
                                  formatter: (cell) =>
                                    moment(cell).format(
                                      "MM/DD/YYYY hh:mm:ss A"
                                    ),
                                },
                                {
                                  dataField: "createdAt",
                                  text: "Created at",
                                  sort: true,
                                  formatter: (cell) =>
                                    moment(cell).format(
                                      "MM/DD/YYYY hh:mm:ss A"
                                    ),
                                },
                              ]}
                              search
                            >
                              {(props) => (
                                <div className="py-4 px-4 table-responsive">
                                  <Container fluid>
                                    <Row>
                                      <Col xs={12} sm={9}>
                                        <ButtonGroup className="float-left">
                                          <Button
                                            className="buttons-copy buttons-html5"
                                            color="primary"
                                            size="sm"
                                            id="copy-tooltip"
                                            onClick={() =>
                                              copyToClipboardAsTable(
                                                document.getElementById(
                                                  "react-bs-table"
                                                )
                                              )
                                            }
                                          >
                                            <span>Copy</span>
                                          </Button>
                                          <ReactToPrint
                                            trigger={() => (
                                              <Button
                                                color="primary"
                                                size="sm"
                                                className="buttons-copy buttons-html5"
                                                id="print-tooltip"
                                              >
                                                Print
                                              </Button>
                                            )}
                                            content={() => componentRef.current}
                                          />
                                        </ButtonGroup>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="print-tooltip"
                                        >
                                          This will open a print page with the
                                          visible rows of the table.
                                        </UncontrolledTooltip>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="copy-tooltip"
                                        >
                                          This will copy to your clipboard the
                                          visible rows of the table.
                                        </UncontrolledTooltip>

                                        <div
                                          id="datatable-basic_filter"
                                          className="dataTables_filter px-4 pb-1"
                                        >
                                          <label>
                                            Search:
                                            <SearchBar
                                              className="form-control-sm"
                                              placeholder=""
                                              {...props.searchProps}
                                            />
                                          </label>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <BootstrapTable
                                    ref={componentRef}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    pagination={pagination}
                                    bordered={true}
                                    striped
                                    hover
                                    condensed
                                    id="react-bs-table"
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          )}
                      </Card>
                    </div>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
