import React from "react";
import { Card, CardBody } from "reactstrap";
import waterIcon from "../../assets/img/icons/cards/waterIcon.svg";

function TankCard({ color, product, capacity, tankNumber, tankData }) {
  return (
    <Card
      style={{
        border: `5px solid ${color}`,
        minHeight: "13rem",
      }}
    >
      <CardBody className="p-0">
        <div
          className="text-center py-1 font-weight-bold"
          style={{ background: `${color}` }}
        >
          Tank {tankNumber}
        </div>
        <hr className="p-0 m-0" />
        <div className="p-2">
          <div className="text-capitalize text-center">Product: {product}</div>
          <div className="text-center">Capacity: {capacity}</div>
          <div className="text-center font-weight-bold">
            {(tankData && tankData[tankNumber] && (
              <span> Current Volume: {tankData[tankNumber].values.Volume}</span>
            )) || (
              <span className="text-danger">
                Current Volume: No Data Available
              </span>
            )}
          </div>
          <div className="text-center">
            Ullage:{" "}
            <span className="">
              {tankData &&
              tankData[tankNumber] &&
              tankData[tankNumber]?.values?.Ullage
                ? tankData[tankNumber]?.values?.Ullage
                : "N/A"}
            </span>
          </div>
          {tankData && tankData[tankNumber]?.values?.Water !== "0.00" && (
            <div className="text-center">
              Water:{" "}
              <span className="ml-2 flex items-center">
                {tankData[tankNumber].values.Water}
                <img src={waterIcon} alt="Water Icon" className="ml-2" />
              </span>
            </div>
          )}
          <div className="text-center font-weight-bold">
            {(tankData && tankData[tankNumber] && (
              <span>Report Time: {tankData[tankNumber]?.date}</span>
            )) || (
              <span className="text-danger">
                Report Time : No Data Available
              </span>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default TankCard;
