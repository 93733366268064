import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function SubcriptionManage() {
  const station_id = sessionStorage.getItem("station_subscription_manage_id");
  const [stationDetails, setStationDetails] = useState({});
  const [subscription, setSubscription] = useState({
    dashboard: true,
    alarm: false,
    issue: false,
    workOrder: false,
    inspection: false,
    compliance: false,
    historicalInventory: false,
    shiftReport: false,
    deliveryReport: false,
    generateReport: false,
    document: false,
    shift: false,
    tank: false,
    line: false,
    sensors: false,
  });
  const [subscribeAllCheckbox, setSubscribeAllCheckbox] = useState(false);
  const [tankCmdError, setTankCmdError] = useState("");
  const [fiveMin, setFiveMin] = useState([]);
  const [cmds, setCmds] = useState(["i20100", "i11300"]);
  const [time, setTime] = useState({
    cmd: [],
    time: [],
  });
  const [defaultTankSelected, setDefaultTankSelected] = useState();
  const [updateConfig, setUpdateConfig] = useState(false);
  const location = useLocation();

  const config_data = {
    stationId: station_id,
    subscription,
    cmd: cmds,
    interval: fiveMin,
    time,
  };

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  // get station detail API
  const [
    { loading: getStationLoading, error: getStationError },
    fetchStationDetails,
  ] = adminRoutes(
    {
      url: adminEndpoints.view_station.path,
      method: adminEndpoints.view_station.method,
      headers: config.header,
      data: { id: station_id },
    },
    { manual: false },
  );

  useEffect(() => {
    fetchStationDetails()
      .then((res) => {
        if (res.status === 200) {
          setStationDetails(res.data);
          if (res.data.subscription) {
            setSubscription({
              alarm: res.data.subscription.alarm,
              issue: res.data.subscription.issue,
              workOrder: res.data.subscription.workOrder,
              inspection: res.data.subscription.inspection,
              compliance: res.data.subscription.compliance,
              historicalInventory: res.data.subscription.historicalInventory,
              shiftReport: res.data.subscription.shiftReport,
              deliveryReport: res.data.subscription.deliveryReport,
              generateReport: res.data.subscription.generateReport,
              document: res.data.subscription.document,
              shift: res.data.subscription.shift,
              tank: res.data.subscription.tank,
              line: res.data.subscription.line,
              sensors: res.data.subscription.sensors,
            });
            if (res.data.deviceConfig) {
              if (res.data.deviceConfig.loop_cmd) {
                setCmds(res.data.deviceConfig.loop_cmd);
              }
              if (res.data.deviceConfig.interval) {
                setFiveMin(res.data.deviceConfig.interval);
              }
              if (res.data.deviceConfig.time_cmd) {
                setTime(res.data.deviceConfig.time_cmd);
                setDefaultTankSelected(
                  tankCommandIndex(
                    res.data.deviceConfig.time_cmd.cmd,
                    "i25100",
                    "i20G00",
                  ),
                );
              }
            }
            if (res.data.updateConfig) {
              setUpdateConfig(res.data.updateConfig);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [
    { loading: subscriptionLoading, error: subscriptionError },
    makeSubscription,
  ] = adminRoutes(
    {
      url: adminEndpoints.subscription_update.path,
      method: adminEndpoints.subscription_update.method,
      headers: config.header,
      data: config_data,
    },
    { manual: true },
  );

  // Subscribe All toggle handler
  const handleAllSubscription = () => {
    setSubscribeAllCheckbox(!subscribeAllCheckbox);

    if (!subscribeAllCheckbox) {
      setFiveMin(["i11100", "i11200", "i20100"]);
      setTime({
        cmd: [
          ["i20400", ""],
          ["i20200", ""],
        ],
        time: [
          [5, 0, 0],
          [4, 0, 0],
        ],
      });
    } else {
      let i204Index = time.cmd.findIndex((subArr) => subArr[0] === "i20400");
      let i202Index = time.cmd.findIndex((subArr) => subArr[0] === "i20200");
      const newTime = { ...time };
      newTime.cmd = time.cmd.filter(
        (_, index) => index !== i204Index && index !== i202Index,
      );
      newTime.time = time.time.filter(
        (_, index) => index !== i204Index && index !== i202Index,
      );
      setTime(newTime);
      setFiveMin([]);
    }

    const newState = {};
    for (const key in subscription) {
      if (
        key !== "compliance" &&
        key !== "tank" &&
        key !== "line" &&
        key !== "sensors" &&
        key !== "dashboard"
      ) {
        newState[key] = !subscribeAllCheckbox;
      } else if (key === "dashboard") {
        newState[key] = true;
      } else if (key === "compliance") {
        newState[key] = subscription.compliance;
      } else if (key === "tank") {
        newState[key] = subscription.tank;
      } else if (key === "line") {
        newState[key] = subscription.line;
      } else {
        newState[key] = subscription.sensors;
      }
    }
    setSubscription(newState);
  };

  function handleNewStationSubmit() {
    if (subscription.tank) {
      const hasTankCommand = time.cmd.some(
        (cmd) => cmd[0] === "i25100" || cmd[0] === "i20G00",
      );

      if (!hasTankCommand) {
        setTankCmdError("Command Required.");
        return;
      }
    }

    makeSubscription()
      .then((res) => {
        if (res.status === 200) {
          window.location.href = isWithGateWay
            ? "/admin/with-gateway"
            : "/admin/station-list";
        }
      })
      .catch((err) => console.log(err));
  }

  const handleChange = (event) => {
    const { name, checked } = event.target;

    setSubscription((prevSubscription) => {
      return {
        ...prevSubscription,
        [name]: checked,
      };
    });

    if (name === "alarm") {
      if (!subscription.alarm) {
        setFiveMin([...fiveMin, "i11100", "i11200"]);
      } else {
        let cmd1ToRemove = "i11100";
        let cmd2ToRemove = "i11200";
        let newFiveMin = [...fiveMin];
        newFiveMin = fiveMin.filter((cmd) => {
          return cmd !== cmd1ToRemove && cmd !== cmd2ToRemove;
        });
        setFiveMin(newFiveMin);
      }
    }
    if (name === "historicalInventory") {
      if (!subscription.historicalInventory) {
        setFiveMin([...fiveMin, "i20100"]);
      } else {
        let cmdToRemove = "i20100";
        let newFiveMin = [...fiveMin];
        newFiveMin = fiveMin.filter((cmd) => {
          return cmd !== cmdToRemove;
        });
        setFiveMin(newFiveMin);
      }
    }
    if (name === "shiftReport") {
      if (!subscription.shiftReport) {
        const newState = { ...time };
        newState.cmd.push(["i20400", ""]);
        newState.time.push([5, 0, 0]);
        setTime(newState);
      } else {
        let i204Index = time.cmd.findIndex((subArr) => subArr[0] === "i20400");
        const newTime = { ...time };
        newTime.cmd = time.cmd.filter((_, index) => index !== i204Index);
        newTime.time = time.time.filter((_, index) => index !== i204Index);
        setTime(newTime);
      }
    }
    if (name === "deliveryReport") {
      if (!subscription.deliveryReport) {
        const newState = { ...time };
        newState.cmd.push(["i20200", ""]);
        newState.time.push([4, 0, 0]);
        setTime(newState);
      } else {
        let i202Index = time.cmd.findIndex((subArr) => subArr[0] === "i20200");
        const newTime = { ...time };
        newTime.cmd = time.cmd.filter((_, index) => index !== i202Index);
        newTime.time = time.time.filter((_, index) => index !== i202Index);
        setTime(newTime);
      }
    }
    if (name === "sensors") {
      if (!subscription.sensors) {
        const newState = { ...time };
        newState.cmd.push(["i30100", "I30100"]);
        newState.time.push([1, 30, 0]);
        setTime(newState);
      } else {
        let i301Index = time.cmd.findIndex((subArr) => subArr[0] === "i30100");
        const newTime = { ...time };
        newTime.cmd = time.cmd.filter((_, index) => index !== i301Index);
        newTime.time = time.time.filter((_, index) => index !== i301Index);
        setTime(newTime);
      }
    }
    if (name === "compliance") {
      if (!checked) {
        // remove i25100 or i20G00 and i30000
        let i251Index = time.cmd.findIndex((subArr) => subArr[0] === "i25100");
        let i20GIndex = time.cmd.findIndex((subArr) => subArr[0] === "i20G00");
        let i301Index = time.cmd.findIndex((subArr) => subArr[0] === "i30100");
        const newTime = { ...time };
        newTime.cmd = time.cmd.filter(
          (_, index) =>
            index !== i251Index && index !== i20GIndex && index !== i301Index,
        );
        newTime.time = time.time.filter(
          (_, index) =>
            index !== i251Index && index !== i20GIndex && index !== i301Index,
        );
        setTime(newTime);
        // remove tank, line and sensors subscription
        setSubscription((prevSubscription) => {
          return {
            ...prevSubscription,
            tank: false,
            line: false,
            sensors: false,
            compliance: false,
          };
        });
        // setDefaultTankSelected
        setDefaultTankSelected();
      }
    }
  };

  function handleTankCommand(event) {
    const { value } = event.target;
    if (value === "i25100") {
      setTankCmdError("");

      const newState = { ...time };
      let i20GIndex = time.cmd.findIndex((subArr) => subArr[0] === "i20G00");
      newState.cmd = time.cmd.filter((_, index) => index !== i20GIndex);
      newState.time = time.time.filter((_, index) => index !== i20GIndex);
      newState.cmd.push(["i25100", "I25100"]);
      newState.time.push([1, 0, 0]);
      setTime(newState);
    } else if (value === "i20G00") {
      const newState = { ...time };
      setTankCmdError("");

      let i251Index = time.cmd.findIndex((subArr) => subArr[0] === "i25100");
      newState.cmd = time.cmd.filter((_, index) => index !== i251Index);
      newState.time = time.time.filter((_, index) => index !== i251Index);
      newState.cmd.push(["i20G00", "I20G00"]);
      newState.time.push([1, 0, 0]);
      setTime(newState);
    } else {
      setTankCmdError("Command Require");
      let i251Index = time.cmd.findIndex((subArr) => subArr[0] === "i25100");
      let i20GIndex = time.cmd.findIndex((subArr) => subArr[0] === "i20G00");
      let newTime = { ...time };
      newTime.cmd = time.cmd.filter(
        (_, index) => index !== i251Index && index !== i20GIndex,
      );
      newTime.time = time.time.filter(
        (_, index) => index !== i251Index && index !== i20GIndex,
      );
      setTime(newTime);
    }
  }

  function tankCommandIndex(arr, option1, option2) {
    for (let i = 0; i <= arr.length; i++) {
      if (arr[i][0] === option1 || arr[i][0] === option2) {
        return arr[i][0];
      }
    }
  }

  // API update config
  const [{ data }, updateDeviceConfig] = adminRoutes(
    {
      url: adminEndpoints.updateConfigStatus.path,
      method: adminEndpoints.updateConfigStatus.method,
      headers: config.header,
      data: {
        sid: stationDetails._id,
        updateStatus: updateConfig,
      },
    },
    { manual: true },
  );
  const isWithGateWay =
    location.pathname.split("/")[2].split("-")[0] === "with";
  return (
    <>
      <SimpleHeader name="Subscription Management" parentName="Station" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Subscription Management</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <Row>
                <a
                  href={isWithGateWay ? "./with-gateway" : "./station-list"}
                  className="text-white mx-2 mb-3"
                >
                  <Button size="sm" color="default" type="button">
                    Back
                  </Button>
                </a>
              </Row>
              <h6 className="heading-small text-muted mb-4">
                Station Basic Information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-station-name"
                      >
                        Station Name
                      </label>
                      <Input
                        id="input-station-name"
                        rows="6"
                        type={"text"}
                        value={
                          getStationLoading
                            ? "Loading..."
                            : stationDetails.stationName
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-station-address"
                      >
                        Station Address
                      </label>
                      <Input
                        id="input-station-address"
                        rows="6"
                        type={"text"}
                        value={
                          getStationLoading
                            ? "Loading..."
                            : stationDetails.stationAddress
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-station-address"
                      >
                        City
                      </label>
                      <Input
                        id="input-station-address"
                        rows="6"
                        type={"text"}
                        value={
                          getStationLoading ? "Loading..." : stationDetails.city
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-state"
                      >
                        State
                      </label>
                      <Input
                        id="input-state"
                        rows="6"
                        type={"text"}
                        value={
                          getStationLoading
                            ? "Loading..."
                            : stationDetails.state
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-zip-code"
                      >
                        Zip Code
                      </label>
                      <Input
                        id="input-zip-code"
                        rows="6"
                        type={"text"}
                        value={
                          getStationLoading ? "Loading..." : stationDetails.zip
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-Contact-Person"
                      >
                        Station Contact Person
                      </label>
                      <Input
                        id="input-Contact-Person"
                        rows="6"
                        type={"text"}
                        value={
                          getStationLoading
                            ? "Loading..."
                            : stationDetails.stationContact
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-station-phone"
                      >
                        Station Phone
                      </label>
                      <Input
                        id="input-station-phone"
                        rows="6"
                        type={"text"}
                        value={
                          getStationLoading
                            ? "Loading..."
                            : stationDetails.phone
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-station-email"
                      >
                        Station Email
                      </label>
                      <Input
                        id="input-station-email"
                        rows="6"
                        type={"text"}
                        value={
                          getStationLoading
                            ? "Loading..."
                            : stationDetails.email
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />

              <h6 className="heading-small text-muted mb-4">Subscriptions</h6>
              <Row>
                <Col>
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Subscribe All
                    </div>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={subscribeAllCheckbox}
                        onChange={handleAllSubscription}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <div className="text-right">
                      <input
                        type="checkbox"
                        id="updateConfig"
                        className="mx-2"
                        checked={updateConfig}
                        value={updateConfig}
                        onChange={(e) => setUpdateConfig(e.target.checked)}
                      />
                      <label
                        htmlFor="updateConfig"
                        className="form-control-label"
                      >
                        Update Config
                      </label>
                      <Button
                        className="ml-3"
                        color="primary"
                        type="button"
                        onClick={() => {
                          updateDeviceConfig().then((data) => {
                            if (data.status === 200) {
                              window.location.href = isWithGateWay
                                ? "/admin/with-gateway"
                                : "/admin/station-list";
                            } else {
                              fetchStationDetails();
                            }
                          });
                        }}
                      >
                        Send
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className="px-lg-4">
                <Row>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Alarm
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="alarm"
                          checked={subscription.alarm}
                          value={subscription.alarm}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Issue
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="issue"
                          checked={subscription.issue}
                          value={subscription.issue}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Work Order
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="workOrder"
                          checked={subscription.workOrder}
                          value={subscription.workOrder}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Inspection
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="inspection"
                          checked={subscription.inspection}
                          value={subscription.inspection}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Historial Inventory
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="historicalInventory"
                          checked={subscription.historicalInventory}
                          value={subscription.historicalInventory}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Shift Report
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="shiftReport"
                          checked={subscription.shiftReport}
                          value={subscription.shiftReport}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Delivery Report
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="deliveryReport"
                          checked={subscription.deliveryReport}
                          value={subscription.deliveryReport}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Generate Report
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="generateReport"
                          checked={subscription.generateReport}
                          value={subscription.generateReport}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Documents
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="document"
                          checked={subscription.document}
                          value={subscription.document}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4" lg="3">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Shift
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="shift"
                          checked={subscription.shift}
                          value={subscription.shift}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                </Row>

                <hr className="my-4" />
                <Row>
                  <Col>
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        Compliances
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="compliance"
                          checked={subscription.compliance}
                          value={subscription.compliance}
                          onChange={handleChange}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  {subscription.compliance && (
                    <>
                      {" "}
                      <Col>
                        <FormGroup>
                          <div
                            className="mb-2"
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "600",
                            }}
                          >
                            Tank
                          </div>
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              name="tank"
                              checked={subscription.tank}
                              value={subscription.tank}
                              onChange={handleChange}
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                        </FormGroup>
                        {subscription.tank && (
                          <FormGroup>
                            <label htmlFor="cmdToSelect">
                              Command To Select{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              type="select"
                              id="cmdToSelect"
                              className={tankCmdError ? "is-invalid" : ""}
                              onChange={handleTankCommand}
                              defaultValue={defaultTankSelected || ""}
                              required={subscription.tank}
                            >
                              <option value="">Select Command</option>
                              <option value="i25100">i25100</option>
                              <option value="i20G00">i20G00</option>
                            </Input>
                            {subscription.tank && tankCmdError && (
                              <div className="text-danger">{tankCmdError}</div>
                            )}
                          </FormGroup>
                        )}
                      </Col>
                      <Col>
                        <FormGroup>
                          <div
                            className="mb-2"
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "600",
                            }}
                          >
                            Line
                          </div>
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              name="line"
                              checked={subscription.line}
                              value={subscription.line}
                              onChange={handleChange}
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <div
                            className="mb-2"
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "600",
                            }}
                          >
                            Sensors
                          </div>
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              name="sensors"
                              checked={subscription.sensors}
                              value={subscription.sensors}
                              onChange={handleChange}
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                        </FormGroup>
                      </Col>{" "}
                    </>
                  )}
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="default"
              onClick={handleNewStationSubmit}
              disabled={subscriptionLoading}
            >
              Subscribe
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default SubcriptionManage;
