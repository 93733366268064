import React from "react";
import CustomProfileHeader from "components/Headers/CustomProfileHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import { useState } from "react";
import { useEffect } from "react";

function ClientEmployeeDetails() {
  const employee_id = sessionStorage.getItem("client_employee_manage_id");
  const [unassignEmployeeModal, setUnassignEmployeeModal] = useState(false);
  const [unassignConfirmation, setUnassignConfirmation] = useState("");
  const [confirmationError, setConfirmationError] = useState("");

  useEffect(() => {
    if (unassignConfirmation.length !== 7) {
      setConfirmationError("Please type confirm");
    } else if (unassignConfirmation !== "confirm") {
      setConfirmationError("Please type confirm");
    } else {
      setConfirmationError("");
    }
  }, [unassignConfirmation]);

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;
  const [
    {
      data: getClientEmployee,
      loading: getClientEmployeeLoading,
      error: getClientEmployeeError,
    },
    refreshClientEmployee,
  ] = Routes({
    url: endpoints.view_client_employee.path,
    method: endpoints.view_client_employee.method,
    headers: config.header,
    data: {
      id: employee_id,
    },
  });
  console.log("get Client Employee", getClientEmployee);

  function closeModal() {
    if (unassignEmployeeModal) {
      setUnassignEmployeeModal(false);
    } else {
      setUnassignEmployeeModal(true);
    }
  }

  const [
    {
      data: unassignClientEmployee,
      loading: unassignClientEmployeeLoading,
      error: unassignClientEmployeeError,
    },
    unassignClientEmployeeFromStation,
  ] = Routes(
    {
      url: endpoints.unassign_client_employee_from_station.path,
      method: endpoints.unassign_client_employee_from_station.method,
      headers: config.header,
      data: {
        eid: employee_id,
        sid: getClientEmployee?.stationDetails?._id,
      },
    },
    { manual: true },
  );

  function handleUnassignSubmit() {
    closeModal();
    unassignClientEmployeeFromStation()
      .then((data) => {
        console.log("data", data);
        refreshClientEmployee();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <CustomProfileHeader heading={"Client Employee Details"} />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={unassignEmployeeModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Unassign Employee Confirmation
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-unassign-confirmation"
                    >
                      Type confirm to unassign employee
                    </label>
                    <Input
                      id="input-unassign-confirmation"
                      className={confirmationError ? "is-invalid" : ""}
                      type={"text"}
                      placeholder={"confirm"}
                      maxLength={7}
                      onChange={(e) => setUnassignConfirmation(e.target.value)}
                    />
                    {confirmationError && (
                      <div className="text-danger">{confirmationError}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={handleUnassignSubmit}
            disabled={confirmationError ? true : false}
          >
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      {getClientEmployee && (
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-lg-1" lg="12">
              <Card className="card-profile">
                <CardHeader className="border-0 pt-4 pt-md-4 pb-0 pb-md-4">
                  <div className="row">
                    <div className="col-sm-2 col-md-2 col-lg-1 align-self-center text-center">
                      <a
                        href="./client-manager-list"
                        className="text-white btn btn-sm btn-default d-print-none"
                      >
                        Back
                      </a>
                    </div>
                    <div className="col-sm-8 col-md-8 col-lg-10">
                      <div className="text-center display-1">
                        {getClientEmployee.name}
                      </div>
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-1 align-self-center text-center">
                      <a
                        href={"/admin/client-employee-edit"}
                        className="btn btn-warning btn-sm d-print-none"
                        onClick={(e) => {
                          sessionStorage.setItem(
                            "client_employee_edit_id",
                            employee_id,
                          );
                        }}
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <div className="card-profile-stats d-flex justify-content-center"></div>
                    </div>
                  </Row>
                  <Row className="p-4">
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Email :{" "}
                        <a href={"mailto:"}>{getClientEmployee.email}</a>
                      </span>
                    </Col>
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Phone : <a href={"tel:"}>{getClientEmployee.phone}</a>
                      </span>
                    </Col>
                  </Row>
                  <hr />
                  {getClientEmployee.stationDetails && (
                    <>
                      <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-center">
                        <h6 className="heading-small text-muted align-middle">
                          Assigned Station Details
                        </h6>
                        <button
                          onClick={closeModal}
                          className="btn btn-danger btn-sm d-print-none"
                        >
                          Unassign
                        </button>
                      </div>
                      <Row className="px-4">
                        <Col lg="6" className={"h3 mt-2"}>
                          <span className={"h4 font-weight-light p-1"}>
                            Station Name :{" "}
                            {getClientEmployee.stationDetails.stationName}
                          </span>
                        </Col>
                        <Col lg="6" className={"h3 mt-2"}>
                          <span className={"h4 font-weight-light p-1"}>
                            Station Address :{" "}
                            {getClientEmployee.stationDetails.stationAddress}
                          </span>
                        </Col>
                        <Col lg="6" className={"h3 mt-2"}>
                          <span className={"h4 font-weight-light p-1"}>
                            Station Email :{" "}
                            {getClientEmployee.stationDetails.email}
                          </span>
                        </Col>
                        <Col lg="6" className={"h3 mt-2"}>
                          <span className={"h4 font-weight-light p-1"}>
                            Station Phone :{" "}
                            {getClientEmployee.stationDetails.phone}
                          </span>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default ClientEmployeeDetails;
