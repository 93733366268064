import SimpleHeader from "components/Headers/SimpleHeader.js";
import { adminRoutes } from "endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactToPrint from "react-to-print";
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            defaultValue={"100"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

export default function DeviceManagementLogs() {
  const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    {
      data: getAllDeviceManagementLogs,
      loading: getAllDeviceManagementLogsLoading,
      error: getAllDeviceManagementLogsError,
    },
    refresh,
  ] = adminRoutes(
    {
      url: adminEndpoints.device_management_logs.path,
      method: adminEndpoints.device_management_logs.method,
      headers: config.header,
    },
    { manual: false }
  );

  return (
    <>
      {alert}
      <SimpleHeader name="Device Management Logs" parentName="Device" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              {getAllDeviceManagementLogs && (
                <ToolkitProvider
                  data={getAllDeviceManagementLogs}
                  keyField="deviceID"
                  columns={[
                    {
                      dataField: "deviceID",
                      text: "Device ID",
                      sort: true,
                    },
                    {
                      dataField: "action_taken",
                      text: "Action taken",
                      sort: true,
                    },
                    {
                      dataField: "action_taken_by",
                      text: "Action taken by",
                      sort: true,
                    },
                    {
                      dataField: "action_taken_time",
                      text: "Action taken time",
                      sort: true,
                      formatter: (cell) =>
                        moment(cell).format("MM/DD/YYYY hh:mm:ss A"),
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={9}>
                            <ButtonGroup className="float-left">
                              <Button
                                className="buttons-copy buttons-html5"
                                color="primary"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>

                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
