import React, { useState } from "react";
import CustomProfileHeader from "components/Headers/CustomProfileHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import { useEffect } from "react";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            defaultValue={"100"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function ClientManagerDetails() {
  const componentRef = React.useRef(null);
  const manager_id = sessionStorage.getItem("client_manager_manage_id");
  const [stationId, setStationId] = useState("");
  const [unassignManagerModal, setUnassignManagerModal] = useState(false);
  const [unassignConfirmation, setUnassignConfirmation] = useState("");
  const [confirmationError, setConfirmationError] = useState("");

  useEffect(() => {
    if (unassignConfirmation.length !== 7) {
      setConfirmationError("Please type confirm");
    } else if (unassignConfirmation !== "confirm") {
      setConfirmationError("Please type confirm");
    } else {
      setConfirmationError("");
    }
  }, [unassignConfirmation]);

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;
  const [
    {
      data: getClientManager,
      loading: getClientManagerLoading,
      error: getClientManagerError,
    },
    refreshClientManager,
  ] = Routes({
    url: endpoints.view_client_manager.path,
    method: endpoints.view_client_manager.method,
    headers: config.header,
    data: {
      id: manager_id,
    },
  });
  console.log("get Client Manager", getClientManager);

  // ADD UNASSIGN BUTTON
  if (getClientManager) {
    getClientManager.station.map(
      (station, idx) =>
        (station.btn = (
          <button
            className="btn btn-danger btn-sm d-print-none"
            onClick={(e) => {
              setStationId(station._id);
              closeModal();
            }}
          >
            Unassign
          </button>
        )),
    );
  }

  // CONFIRMATION MODAL
  function closeModal() {
    if (unassignManagerModal) {
      setUnassignManagerModal(false);
    } else {
      setUnassignManagerModal(true);
    }
  }

  const [
    {
      data: unassignClientManager,
      loading: unassignClientManagerLoading,
      error: unassignClientManagerError,
    },
    unassignClientManagerFromStation,
  ] = Routes(
    {
      url: endpoints.unassign_client_manager_from_station.path,
      method: endpoints.unassign_client_manager_from_station.method,
      headers: config.header,
      data: {
        mid: manager_id,
        sid: stationId,
      },
    },
    { manual: true },
  );

  // HANDLE CONFIRMATION SUBMIT
  function handleUnassignSubmit() {
    closeModal();
    unassignClientManagerFromStation()
      .then((data) => {
        console.log("data", data);
        refreshClientManager();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <CustomProfileHeader heading={"Client Manager Details"} />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={unassignManagerModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Unassign Manager Confirmation
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-unassign-confirmation"
                    >
                      Type confirm to unassign manager
                    </label>
                    <Input
                      id="input-unassign-confirmation"
                      className={confirmationError ? "is-invalid" : ""}
                      type={"text"}
                      placeholder={"confirm"}
                      maxLength={7}
                      onChange={(e) => setUnassignConfirmation(e.target.value)}
                    />
                    {confirmationError && (
                      <div className="text-danger">{confirmationError}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={handleUnassignSubmit}
            disabled={confirmationError ? true : false}
          >
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      {getClientManager && (
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-lg-1" lg="12">
              <Card className="card-profile">
                <CardHeader className="border-0 pt-4 pt-md-4 pb-0 pb-md-4">
                  <div className="row ">
                    <div className="col-sm-2 col-md-2 col-lg-1 align-self-center text-center">
                      <a
                        href="./client-manager-list"
                        className="btn btn-sm btn-default"
                      >
                        Back
                      </a>
                    </div>
                    <div className="col-sm-8 col-md-8 col-lg-10">
                      <div className="text-center display-1">
                        {getClientManager.name}
                      </div>
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-1 align-self-center text-center">
                      <a
                        href={"/admin/client-manager-edit"}
                        className="btn btn-warning btn-sm d-print-none"
                        onClick={(e) => {
                          sessionStorage.setItem(
                            "client_manager_edit_id",
                            manager_id,
                          );
                        }}
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row className="justify-content-end mx-4"></Row>
                  <Row>
                    <div className="col-12">
                      <div className="card-profile-stats d-flex justify-content-center"></div>
                    </div>
                  </Row>
                  <Row className="p-4">
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Email : <a href={"mailto:"}>{getClientManager.email}</a>
                      </span>
                    </Col>
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Phone : <a href={"tel:"}>{getClientManager.phone}</a>
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* STATION LIST */}
          {getClientManager.station.length > 0 && (
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Assigned Station List</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <Row>
                <div className="col">
                  <ToolkitProvider
                    data={getClientManager.station}
                    keyField="_id"
                    columns={[
                      {
                        dataField: "stationName",
                        text: "Name",
                        sort: true,
                      },
                      {
                        dataField: "stationAddress",
                        text: "Address",
                        sort: true,
                      },
                      {
                        dataField: "phone",
                        text: "Phone",
                        sort: false,
                      },
                      {
                        dataField: "email",
                        text: "Email",
                        sort: false,
                      },
                      {
                        dataField: "btn",
                        text: "Action",
                        sort: false,
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter pb-1"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={true}
                          striped
                          hover
                          condensed
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Row>
            </Card>
          )}
        </Container>
      )}
    </>
  );
}

export default ClientManagerDetails;
