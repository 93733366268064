import React, { useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select/creatable";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            defaultValue={"100"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function StationsWithGateway() {
  const LOGGED_IN_USER_NAME = sessionStorage.getItem("name");
  const RESTART_DEVICE_PASSWORD = "TOC404";
  const [alert, setAlert] = React.useState(null);
  const [isActiveModal, setisActiveModal] = React.useState(false);
  const [deviceToRestart, setDeviceToRestart] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [disableRestartBtn, setdisableRestartBtn] = React.useState(true);
  const [restartReason, setRestartReason] = React.useState("");
  const componentRef = React.useRef(null);

  useEffect(() => {
    if (password === RESTART_DEVICE_PASSWORD) {
      setdisableRestartBtn(false);
    } else {
      setdisableRestartBtn(true);
    }
  }, [password]);

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>,
    );
  };

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;
  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshStation,
  ] = Routes(
    {
      url: endpoints.list_station.path,
      method: endpoints.list_station.method,
      headers: config.header,
    },
    { manual: false },
  );

  const [
    { loading: restartDeviceLoading, error: restartDeviceError },
    restartDevice,
  ] = Routes(
    {
      url: endpoints.restart_device.path,
      method: endpoints.restart_device.method,
      headers: config.header,
    },
    { manual: true },
  );

  if (getStation) {
    getStation.data.map(
      (data, idx) =>
        (data.btn = (
          <>
            <ButtonGroup>
              <a
                href={"./with-gateway-station-edit"}
                className="btn btn-warning  btn-sm  d-print-none"
                onClick={(e) => {
                  sessionStorage.setItem("station_edit_id", data._id);
                  sessionStorage.setItem(
                    "station_file_manage_name",
                    data.stationName,
                  );
                }}
              >
                Edit
              </a>
              <a
                href={"./with-gateway-station-details"}
                className="btn btn-success  btn-sm  d-print-none"
                onClick={(e) =>
                  sessionStorage.setItem("station_manage_id", data._id)
                }
              >
                Details
              </a>
              <a
                href="./subscription-manage"
                className="btn btn-info btn-sm"
                onClick={(e) =>
                  sessionStorage.setItem(
                    "station_subscription_manage_id",
                    data._id,
                  )
                }
              >
                Subscription
              </a>
            </ButtonGroup>
            <ButtonGroup>
              <button
                className="btn btn-danger  btn-sm  d-print-none"
                onClick={() => {
                  setDeviceToRestart(data.deviceID);
                  setisActiveModal(true);
                }}
              >
                Restart
              </button>
              <a
                href="./device-logs"
                className="btn btn-info btn-sm  d-print-none"
                onClick={(e) =>
                  sessionStorage.setItem("device_id", data.deviceID)
                }
              >
                Device Logs
              </a>
            </ButtonGroup>
          </>
        )),
    ); //to add edit and details button in the table
    getStation.data.map(
      (data, idx) =>
        (data.fm_btn = (
          <a
            href={"./station-files"}
            className="btn btn-primary  btn-sm  d-print-none"
            onClick={(e) => {
              sessionStorage.setItem("station_file_manage_id", data._id);
              sessionStorage.setItem(
                "station_file_manage_name",
                data.stationName,
              );
            }}
          >
            Files
          </a>
        )),
    ); //to add file manager button
    getStation.data.map(
      (data) =>
        (data.gatewayStatus = (
          <Badge color={data.gateway ? "success" : "danger"}>
            {data.gateway ? "With Gateway" : "Without Gateway"}
          </Badge>
        )),
    );
  }

  const toggleModal = () => {
    setisActiveModal(!isActiveModal);
    setDeviceToRestart(null);
    setPassword("");
    setRestartReason("");
  };

  const RESTART_DEVICE_REASON_OPTIONS = [
    { value: "For Giving OTA", label: "For Giving OTA" },
    {
      value: "For Updating Configuration",
      label: "For Updating Configuration",
    },
    { value: "For Maintenance", label: "For Maintenance" },
  ];

  const handleRestartDeviceSubmit = async () => {
    const data = {
      device: deviceToRestart,
      reason: restartReason,
      user: LOGGED_IN_USER_NAME,
    };

    try {
      const response = await restartDevice({ data });
      if (response.status === 200) {
        toggleModal();
        setDeviceToRestart(null);
        setPassword("");
        setRestartReason("");
      }
    } catch (error) {
      console.log(error);
      setAlert(
        <ReactBSAlert
          error
          style={{ display: "block", marginTop: "-100px" }}
          title="Error Restarting Device "
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          btnSize=""
        />,
      );
    }
  };

  return (
    <>
      {alert}
      <SimpleHeader name="Stations With Gateway" parentName="Station" />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              {getStation && (
                <ToolkitProvider
                  data={getStation?.data?.filter((data) => data?.gateway)}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "stationName",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "deviceID",
                      text: "Device",
                      sort: true,
                    },
                    {
                      dataField: "ATGModel",
                      text: "ATG Model",
                      sort: true,
                    },
                    {
                      dataField: "stationAddress",
                      text: "Address",
                      sort: true,
                    },

                    {
                      dataField: "city",
                      text: "City",
                      sort: false,
                    },
                    {
                      dataField: "phone",
                      text: "Phone",
                      sort: false,
                    },
                    {
                      dataField: "gatewayStatus",
                      text: "Gateway",
                      sort: true,
                      headerStyle: { textAlign: "center" },
                      style: {
                        textAlign: "center",
                      },
                    },
                    {
                      dataField: "fm_btn",
                      text: "Files",
                      sort: false,
                      headerStyle: { textAlign: "center" },
                      style: {
                        textAlign: "center",
                      },
                    },
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                      headerStyle: { textAlign: "center" },
                      style: {
                        textAlign: "center",
                      },
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="primary"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table"),
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={6}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={isActiveModal}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Active/Deactive Account
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Password
                      <input
                        type="password"
                        className="form-control form-control-sm w-100"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Reason
                      <Select
                        options={RESTART_DEVICE_REASON_OPTIONS}
                        id={"station"}
                        onChange={(e) => {
                          setRestartReason(e.value);
                        }}
                        required
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={handleRestartDeviceSubmit}
            disabled={disableRestartBtn || restartDeviceLoading}
          >
            Submit
          </Button>
          <Button onClick={toggleModal} disabled={restartDeviceLoading}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}
export default StationsWithGateway;
